.App {
  text-align: center;
  height: 100vh; 
  width:  100vw; 
  position: fixed;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  align-items: center;
  position:fixed;
  font-size: calc(10px + 2vmin);
  top: 0;
  width:100%;
  height: 60px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.Navbar {
  font-size: calc(10px + 2vmin);
  display: flex;
  align-items: center;
  width:100%;
  height:50px;
  justify-content: space-between;
}

.cardContainer {
  align-items:center;
  width:100vw;
  height:100vh;
  overflow: auto;
}

.card {
  background-color:#3e434f;
  width: 60vw;
  height: 70vh;
  max-width:1000px;
  max-height:700px;
  font-size: calc(10px + 2vmin);
  border-radius: .5vw;
  margin:auto;
}

.sCard {
  background-color:#3e434f;
  width:40vw;
  height: 40vh;
  max-width:600px;
  max-height:400px;
  border-radius: .5vw;
  margin:auto;
}


.heroCard{
  width:80vw;
  height: 65vh;
  border-radius: .5vw;
  margin:auto;
	align-items: center;
	justify-content: center;
  display:flex;
  flex-direction: column;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.links{
  bottom:1vw;
  left: 5vw;
  position:fixed;
  width: 3vw;
}

.image{
  max-width: 100%;
  max-height: 100%;
}

.image:hover{
  filter: invert(100%);
}

.picture {
  filter: grayscale(50%);
  -webkit-filter: grayscale(50%);
}

.picture:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}
.portrait{
  margin-top: 1vh;
  width: 3vw;
  height: 5vh;
}

.line{
  margin-top: 1vh;
  margin-bottom: -2vh;
  height: 10vh;
}

.smallerDivider{
  height:5vh;
}

.divider{
  height:10vh;
}

.biggerDivider{
  height:14vh;
}
/* Already have an arrow */

li { 	
  list-style:none; 
  text-align: left;
  font-size: calc(10px + 2vmin);
} 

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

.diamond{
  height: .75em;
  width: .75em;
  filter: invert(80%) sepia(9%) saturate(554%) hue-rotate(181deg) brightness(90%) contrast(82%);}

.arrow {
	width: 0;
	height: 60px;
	border: 1px solid #333;
	position: relative;
	animation: scroll 1.5s infinite;
	-webkit-animation: scroll 1.5s infinite;
	&::after {
		content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: -5px;
    width: 1px;
    height: 10px;
		
    border-top: 10px solid #333;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
	}
}

@keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

@-webkit-keyframes scroll {
	0% {
		height: 40px;
	}
	30% {
		height: 70px;
	}
	60% {
		height: 40px;
	}
}

.fadeIn {
  -webkit-animation: fadein 10s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 10s; /* Firefox < 16 */
      -ms-animation: fadein 10s; /* Internet Explorer */
       -o-animation: fadein 10s; /* Opera < 12.1 */
          animation: fadein 10s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
